@use '../../fundation/index' as *;

.c-table {
  width: 100%;

  th,
  td {
    padding: 10px 6px;
    text-align: start;
    vertical-align: top;

    svg {
      width: 1em;
    }
  }

  th {
    background-color: $clr_border;

    &:not(:last-of-type) {
      border-right: 1px solid #fff;
    }
  }

  td {
    border: 1px solid $clr_border;
  }

  td.-empty,
  th.-empty {
    border: none;
    background-color: transparent;
  }

  .-primary {
    background-color: $clr_bg;
  }

  tr:not(:last-of-type) {
    .-primary {
      border-bottom-color: #fff;
    }
  }

  @include mq(large) {
    th,
    td {
      padding: 20px 32px;

      svg {
        width: 1.125em;
      }
    }
  }
}
