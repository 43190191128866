@use './variable' as *;

/*  base
================================================ */
*,
*::before,
*::after {
  min-width: 0;
}
html {
  overflow: auto;
  scroll-padding-top: calc(var(--size-header-h) + 20px) !important;
  &:not([lang='ja']) {
    word-break: break-word;
  }
}
body {
  min-width: 320px;
  font-feature-settings: 'palt';
  color: $clr_default;
  @include font_base();
  overflow: clip visible;
  @include mq(medium) {
    min-width: auto;
  }

  *:lang(en) {
    @include font_en;
  }

  figure {
    margin: 0;
  }
}
