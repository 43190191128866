@use '../../fundation/index' as *;

// =============================
// 本文
// =============================
.p-contact {
  .p-contact__inner {
    @include inner(920);
  }

  .p-contact__text {
    margin-bottom: 60px;
  }

  //ナビゲーション
  .p-contact__list {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 8px 1fr 8px 1fr;
    gap: 5px;
  }
  //アイテム
  .p-contact__list-item {
    padding: 9px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.4;

    span {
      @include font_en;
    }

    &[aria-hidden='true'] {
      border: 1px solid $clr_border_secondary;
    }
    &:not([aria-hidden='true']) {
      color: #fff;
      background-color: $clr_primary;
    }
  }
  //矢印
  .p-contact__list-arrow {
    height: 12px;
    background-color: $clr_border;
    align-self: center;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    .p-contact__text {
      margin-bottom: 80px;
    }

    .p-contact__list {
      margin-bottom: 60px;
      grid-template-columns: 1fr 13px 1fr 13px 1fr;
      gap: 10px;
    }
    .p-contact__list-item {
      padding: 16px;
      flex-direction: row;
      align-items: baseline;
      gap: 8px;
    }
    .p-contact__list-arrow {
      height: 20px;
    }
  }
}

// =============================
// フォーム
// =============================
.p-form__wrap {
  font-size: font-size(16);
}

.p-form__bg {
  margin-inline: calc(50% - 50vw);
  margin-bottom: 32px;
  padding: 60px 24px;
  background-color: $clr_bg;

  @include mq(medium) {
    margin-inline: 0px;
    margin-bottom: 60px;
    padding: 80px;
  }
}

.p-form {
  input[type='text'],
  input[type='tel'],
  input[type='number'],
  input[type='email'],
  textarea {
    padding: 16px;
    width: 100%;
    line-height: 1.4;
    border: 1px solid $clr_border;

    &::placeholder {
      color: $clr_txt_gray;
    }
  }

  textarea {
    min-height: calc(6em * 1.9);
    field-sizing: content;
  }

  label {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @include mq(large) {
    textarea {
      min-height: calc(10em * 1.9);
    }
  }
}

.-required {
  padding-inline: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: font-size(12);
  color: #fff;
  background-color: $clr_primary;
}

.p-form-cont {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mq(large) {
    margin-bottom: 50px;
  }
}

//チェックボックス
input[type='checkbox'] {
  position: absolute;
  border: 0;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.p-form-checkbox {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > * {
    margin: 0 !important;
    width: fit-content;
  }

  .mwform-checkbox-field-text {
    --checkbox-size: 20px;

    padding-left: 32px;
    position: relative;
    font-weight: 500;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      width: var(--checkbox-size);
      height: var(--checkbox-size);
      position: absolute;
      left: 0;
      top: calc((1em * 1.9 - var(--checkbox-size)) / 2);
      background-color: #fff;
    }
    &::before {
      border: 1px solid $clr_border;
    }
    &::after {
      background-color: $clr_primary;
      scale: 0;
    }
  }

  input[type='checkbox']:checked + .mwform-checkbox-field-text {
    &::after {
      scale: 0.45;
    }
  }
  input[type='checkbox']:focus-visible + .mwform-checkbox-field-text {
    outline: auto;
    outline: auto -webkit-focus-ring-color; /* for Chrome */
  }
}

//プライバシーポリシー
.p-form-policy {
  .p-form-policy__text {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .mwform-checkbox-field-text {
    --checkbox-size: 20px;

    padding-left: 32px;
    display: block;
    width: fit-content;
    position: relative;
    font-weight: 500;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      width: var(--checkbox-size);
      height: var(--checkbox-size);
      position: absolute;
      left: 0;
      top: calc((1em * 1.9 - var(--checkbox-size)) / 2);
      background-color: #fff;
    }
    &::before {
      border: 1px solid $clr_border;
    }
    &::after {
      background-color: $clr_primary;
      scale: 0;
    }
  }
  input[type='checkbox']:checked + .mwform-checkbox-field-text {
    &::after {
      scale: 0.45;
    }
  }
  input[type='checkbox']:focus-visible + .mwform-checkbox-field-text {
    outline: auto;
    outline: auto -webkit-focus-ring-color; /* for Chrome */
  }

  //リンク
  .p-form-policy__link {
    margin-block: 8px 16px;
    display: block;
    width: fit-content;
    color: $clr_txt_gray;
    font-size: font-size(14);
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }
}

//ボタン
.p-form__btnWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @include mq(large) {
    flex-direction: row;
  }
}

.p-form__btn-reverse {
  color: $clr_primary;
  background-color: #fff;

  span {
    &::after {
      background-color: $clr_primary;
    }
  }

  &:hover,
  &:focus-visible {
    color: #fff;
    background-color: $clr_primary;

    span {
      &::after {
        background-color: #fff;
      }
    }
  }
}

// 入力画面
// =============================
.contact {
  @include mq(large) {
    .p-form-policy {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);

      .p-form-policy__link {
        justify-self: end;
      }
      .mwform-checkbox-field {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }
    }
  }
}
// 確認画面
// =============================
.confirm {
  .p-form-policy__link {
    display: none;
  }
}

// 完了画面
// =============================
.p-form__thanks {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
