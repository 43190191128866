@use '../../fundation/index' as *;

// =============================
// MV
// =============================
.p-index-mv {
  --mv-marginTop: 76px;
  --mv-animation-start-time: 0.2s;
  --mv-animation-time: 0.5s;
  --mv-animation-delay: 0.6s;

  margin-top: var(--mv-marginTop);
  height: calc(812px - var(--mv-marginTop));
  overflow: hidden;
  position: relative;
  z-index: 2;

  // タイトル
  // =============================
  .p-index-mv__title-sub {
    margin-block: 20px;
    color: $clr_primary;
    @include font_title;
    font-size: clamp(0.875rem, 0.125rem + 2.14vw, 2rem);
    overflow: hidden;

    span {
      display: block;
      translate: 0 120%;
      transition: var(--mv-animation-time) calc(var(--mv-animation-start-time)) ease-in-out;
      transition-property: translate;
    }
  }
  .p-index-mv__title-img {
    padding-block: 20px;
    width: clamp(18.75rem, 53.57vw, 46.875rem);
    position: relative;
    display: block;
    overflow: hidden;

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $clr_default;
      position: absolute;
      left: 0;
      top: 0;
      scale: 0 1;
      transform-origin: center left;
      transition: var(--mv-animation-time) calc(var(--mv-animation-delay) * 1 + var(--mv-animation-start-time)) ease-in-out;
      transition-property: scale;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      translate: 0 200%;
      transition: calc(var(--mv-animation-time) * 1.4) calc(var(--mv-animation-delay) * 1.6 + var(--mv-animation-start-time)) cubic-bezier(0, 0.22, 0.41, 0.98);
      transition-property: translate;
    }

    &.-second {
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: $clr_default;
        position: absolute;
        left: 0;
        bottom: 0;
        scale: 0 1;
        transform-origin: center left;
        transition: var(--mv-animation-time) calc(var(--mv-animation-delay) * 1 + var(--mv-animation-start-time)) ease-in-out;
        transition-property: scale;
      }

      img {
        max-width: clamp(14rem, 0.417rem + 38.81vw, 34.375rem);
        transition: calc(var(--mv-animation-time) * 1.4) calc(var(--mv-animation-delay) * 2 + var(--mv-animation-start-time)) cubic-bezier(0, 0.22, 0.41, 0.98);
      }
    }
  }

  // テキスト
  // =============================
  .p-index-mv__textWrap {
    margin-inline: auto;
    width: fit-content;
    font-size: clamp(1rem, 0.583rem + 1.19vw, 1.625rem);
    display: flex;
    flex-direction: column;
    gap: 10px;

    .p-index-mv__text {
      overflow: hidden;

      @for $i from 1 through 5 {
        &:nth-of-type(#{$i}) {
          .p-index-mv__text-wrap {
            transition: var(--mv-animation-time) calc(var(--mv-animation-delay) * 2.4 + 0.2s * $i - 0.2s + var(--mv-animation-start-time)) ease-in-out;
            transition-property: translate;
          }
        }
      }

      .p-index-mv__text-wrap {
        width: fit-content;
        display: block;
        translate: 0 120%;
      }

      .p-index-mv__text-bg {
        padding-inline: 8px;
        padding-bottom: 4px;
        position: relative;
        z-index: 1;
        transition: var(--mv-animation-time) calc(var(--mv-animation-delay) * 2.4 + 1s + var(--mv-animation-start-time)) ease-in-out;
        transition-property: color;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          background-color: $clr_primary;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          scale: 0 1;
          transform-origin: center left;
          transition: var(--mv-animation-time) calc(var(--mv-animation-delay) * 2.4 + 1s + var(--mv-animation-start-time)) ease-in-out;
          transition-property: scale;
        }
      }
    }
  }

  // 画像
  // =============================
  .p-index-mv__img {
    background-color: $clr_primary;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    img {
      margin-left: -10%;
      display: block;
      width: 120%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 0%;
      transition: object-position 0.2s ease-out;
    }

    &.-first {
      width: 47%;
      max-width: 290px;
      aspect-ratio: 44 / 63;
      top: 0;
      right: 0;
      translate: 30% 0;
    }

    &.-second {
      width: 30%;
      max-width: 180px;
      aspect-ratio: 113 / 141;
      bottom: min(100px, 15%);
      left: 0;
    }

    &.-third {
      width: 70%;
      max-width: 460px;
      aspect-ratio: 2 / 1;
      bottom: 20px;
      right: 0;
      translate: 25% 0;
    }
  }

  // メディアクエリ
  // =============================
  @include mqUnder(768) {
    .p-index-mv__wrap {
      @include inner;
    }
  }

  @include mq(medium) {
    .p-index-mv__wrap {
      margin-left: min(180px, 10vw);
      width: clamp(18.75rem, 53.57vw, 46.875rem);
      max-width: 750px;
    }
    .p-index-mv__textWrap {
      margin-left: auto;
      margin-right: 0;
    }
  }

  @include mq(large) {
    --mv-marginTop: 140px;

    height: 1450px;

    .p-index-mv__wrap {
      margin-left: min(180px, 10vw);
    }
    .p-index-mv__title-sub {
      margin-block: 0 40px;
    }
    .p-index-mv__title-img {
      padding-block: 36px;
    }

    .p-index-mv__textWrap {
      margin-left: 24%;
      gap: 6px;
    }

    .p-index-mv__img {
      &.-first {
        width: 34%;
        max-width: 630px;
        right: 20px;
        aspect-ratio: 630 / 900;
        translate: 0 0;
      }

      &.-second {
        width: 20%;
        max-width: 370px;
        bottom: min(390px, 28%);
      }

      &.-third {
        width: 42%;
        max-width: 780px;
        bottom: 0;
        right: max(24px, calc((100vw - 1180px) / 2));
        translate: 0 0;
      }
    }
  }

  @include mqUpper(1660) {
    .p-index-mv__textWrap {
      margin-left: auto;
    }

    .p-index-mv__img {
      &.-first {
        right: min(120px, 6.5vw);
      }
    }
  }
}

// =============================
// MV アニメーション
// =============================
.is-loaded .p-index-mv {
  // タイトル
  // =============================
  .p-index-mv__title-sub {
    span {
      display: block;
      translate: 0 0;
    }
  }
  .p-index-mv__title-img {
    &::before,
    &::after {
      scale: 1 1;
    }

    img {
      translate: 0 0;
    }
  }

  // テキスト
  // =============================
  .p-index-mv__textWrap {
    .p-index-mv__text-wrap {
      translate: 0 0;
    }
    .p-index-mv__text-bg {
      color: #fff;

      &::before {
        scale: 1 1;
      }
    }
  }
}

// =============================
// solution
// =============================
.p-index-solution {
  padding-top: 190px;
  overflow: hidden visible;
  overflow: clip visible;
  position: relative;

  //bg
  .p-index-solution__bg-text {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: calc(100% - 210px);
  }

  .p-index-solution__bg {
    position: relative;

    //背景
    &::before {
      content: '';
      width: calc(100% - $padding);
      height: calc(100% - 430px);
      background-color: $clr_primary_light;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    //文字
    &::after {
      content: "Nisso's one-stop solution";
      @include font_en;
      text-transform: uppercase;
      font-size: clamp(2.375rem, -1.333rem + 10.6vw, 7.938rem);
      word-break: keep-all;
      color: #fff;
      -webkit-text-stroke: 1px $clr_primary;
      position: absolute;
      left: 12px;
      top: 0;
      translate: 0 -75%;
    }
  }

  .p-index-solution__outer {
    @include inner;
  }
  .p-index-solution__inner {
    padding-block: 80px 90px;
    padding-left: $padding;
  }

  // タイトル
  // =============================
  .p-index-solution__title {
    @include font_title;
    font-size: clamp(1.75rem, 1.083rem + 1.9vw, 2.75rem);
  }

  // コンテナ
  // =============================
  .p-index-solution__wrap {
    margin-block: 40px 60px;
  }

  //かくアイテム
  .p-index-solution__item {
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px solid $clr_default;

    &:last-of-type {
      border-bottom: 1px solid $clr_default;
    }
  }
  .p-index-solution__item-titleWrap {
    width: fit-content;
    height: fit-content;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 120%;
      height: 1px;
      background-color: $clr_default;
      position: absolute;
      left: -10%;
      scale: 0 1;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }
  .p-index-solution__item-titleWrap-inner {
    &::before,
    &::after {
      content: '';
      width: 1px;
      height: 120%;
      background-color: $clr_default;
      position: absolute;
      top: -10%;
      scale: 1 0;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  .p-index-solution__item-title {
    padding: 10px 32px;
    width: fit-content;
    font-size: clamp(1.25rem, 0.917rem + 0.95vw, 1.75rem);
    @include font_title;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    transition: 0.4s 0.6s ease-in-out;
    transition-property: clip-path;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $clr_default;
      z-index: -1;
    }
  }

  .p-index-solution__item-text {
    font-size: font-size(16);

    span {
      color: $clr_primary;
    }
  }

  // 展示会開催
  // =============================
  .p-index-solution__area {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    position: relative;
  }

  .p-index-solution__area-textWrap {
    position: relative;
    z-index: 2;
  }

  .p-index-solution__area-title {
    margin-bottom: 16px;
    font-size: clamp(1.25rem, 0.75rem + 1.43vw, 2rem);
    @include font_title;
  }

  .p-index-solution__area-text {
    margin-bottom: 32px;
  }

  .p-index-solution__area-btn {
    display: flex;
    justify-content: end;
  }

  //日本地図
  .p-index-solution__area-japan {
    width: 70%;
    position: absolute;
    right: -20%;
    top: 12%;
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    padding-top: 420px;

    .p-index-solution__bg-text {
      max-width: 900px;
      right: -5%;
      bottom: calc(100% - 560px);
      z-index: -1;
    }

    .p-index-solution__bg {
      position: relative;

      &::after {
        max-width: 1180px;
        left: min(120px, 7vw);
        top: 0;
      }
    }

    .p-index-solution__inner {
      padding-block: 192px 200px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }

    .p-index-solution__title {
      margin-bottom: 140px;
    }
    .p-index-solution__title-inner {
      position: sticky;
      top: var(--size-header-h);

      .js-fade__container {
        line-height: 1.2;
      }
    }

    .p-index-solution__wrap {
      margin-block: 0px 140px;
      margin-top: 16px;
    }

    .p-index-solution__item {
      padding-block: 40px 64px;
      gap: 64px;
    }

    .p-index-solution__item-title {
      min-width: 280px;
    }

    .p-index-solution__area {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }

    .p-index-solution__area {
      gap: 70px;
    }

    .p-index-solution__area-textWrap {
      display: grid;
      grid-template-columns: 1fr auto;
    }

    .p-index-solution__area-title {
      margin-bottom: 24px;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    .p-index-solution__area-text {
      margin-bottom: 0;
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    .p-index-solution__area-btn {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
      width: fit-content;
      height: fit-content;
      align-self: center;
    }

    .p-index-solution__area-japan {
      width: 65%;
      right: -34%;
      top: -40%;
      z-index: -1;
    }
  }

  @include mqMinMax(1228, 1400) {
    .p-index-solution__area-japan {
      right: -10%;
    }
  }

  @include mqUpper(1400) {
    .p-index-solution__bg {
      &::before {
        width: calc(100% - min(180px, 10vw));
        height: calc(100% - 500px);
      }
    }
    .p-index-solution__inner {
      padding-left: 0;
    }
  }
}

// =============================
// business
// =============================
.p-index-business {
  //bg
  .p-index-business__bg {
    padding-block: 90px;
    position: relative;

    //背景
    &::before {
      content: '';
      width: calc(100% - $padding);
      height: 100%;
      background-color: $clr_bg;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }

  .p-index-business__outer {
    @include inner;
  }
  .p-index-business__inner {
    padding-right: $padding;
  }

  // 展示会デザイン・施工
  // =============================
  .p-index-business__wrap {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
  }

  //タイトル色違い
  .p-index-business__wrap-title-sub {
    &::before {
      background-color: $clr_primary;
    }
  }

  // どの他の事業
  // =============================
  .p-index-business__container {
    margin-block: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 350px), 1fr));
    gap: 24px;
  }

  .p-index-business__booth {
    figcaption {
      margin-top: 12px;
      text-align: center;
    }
  }

  .p-index-business__btn {
    display: flex;
    justify-content: center;
  }

  // メディアクエリ
  // =============================
  @include mqUnder(1024) {
    .p-index-business__onestop {
      margin-inline: auto;
      max-width: 480px;
    }
  }

  @include mq(large) {
    .p-index-business__bg {
      padding-block: 200px;
    }

    .p-index-business__wrap {
      margin-bottom: 60px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      gap: 60px;
    }
    .p-index-business__textWrap {
      flex-basis: 40%;
      max-width: 480px;
    }
    .p-index-business__img {
      flex-basis: 51%;
      max-width: 600px;
    }

    .p-index-business__container {
      margin-block: 48px 60px;
      gap: 60px 50px;
    }

    .p-index-business__booth {
      figcaption {
        margin-top: 20px;
      }
    }
  }

  @include mqUpper(1400) {
    .p-index-business__bg {
      &::before {
        width: calc(100% - min(180px, 10vw));
      }
    }
    .p-index-solution__inner {
      padding-right: 0;
    }
  }
}

// =============================
// client
// =============================
.p-index-client {
  margin-block: 90px;

  .p-index-client__inner {
    @include inner;
  }

  .p-index-client__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    margin-block: 180px;

    .p-index-client__wrap {
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
  }
}

// =============================
// case & design
// =============================
.p-index-case {
  display: grid;
  background-color: $clr_primary;

  & > * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  //背景
  .p-index-case__bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .p-index-case__inner {
    padding-block: 90px;
    @include inner;
  }

  //タイトル色変え
  .c-headWrap {
    &::before,
    &::after {
      background-color: #fff;
    }
  }
  .p-index-case__title {
    &::before,
    &::after {
      background-color: #fff;
    }
    .c-head__sub {
      color: $clr_default;
      background-color: #fff;
    }
    .c-head__main {
      color: $clr_default;
    }
    .c-head__sub,
    .c-head__main {
      &::before {
        background-color: #fff;
      }
    }
  }
  .p-index-case__parse-title,
  .p-index-case__image-title {
    color: #fff;
    &::before,
    &::after {
      background-color: #fff;
    }
  }

  // 展示会デザイン・施工例
  // =============================
  .p-index-case__parse-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .p-index-case__image-img {
    margin: 0;
  }

  //ボタン
  .p-index-case__image-btnWrap {
    display: flex;
    justify-content: center;
  }
  .p-index-case__image-btn {
    color: $clr_primary;
    background-color: #fff;

    span {
      &::after {
        background-color: $clr_primary;
      }
    }

    &:hover,
    &:focus-visible {
      color: #fff;
      background-color: $clr_primary;
      border-color: #fff;

      span {
        &::after {
          background-color: #fff;
        }
      }
    }
  }

  // メディアクエリ
  // =============================
  @include mqUnder(1024) {
    .p-index-case__parse-container {
      margin-inline: auto;
      max-width: 560px;
    }

    // 展示会デザイン・施工例
    // =============================
    .p-index-case__image-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto);
      gap: 10px;
    }
    .p-index-case__image-img {
      &:nth-of-type(1),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        grid-column: 1 / 2;
      }

      &:nth-of-type(1) {
        grid-row: 1 / 2;
      }
      &:nth-of-type(2) {
        grid-row: 1 / 3;
      }
      &:nth-of-type(3) {
        grid-row: 3 / 4;
      }
      &:nth-of-type(4) {
        grid-row: 2 / 4;
      }
      &:nth-of-type(5),
      &:nth-of-type(6) {
        grid-row: 4 / 5;
      }
    }
  }

  @include mq(large) {
    .p-index-case__inner {
      padding-block: 180px;
    }

    .p-index-case__parse-container {
      margin-right: calc(50% - 50vw);
      flex-direction: row;
      gap: 20px;
    }

    // 展示会デザイン・施工例
    // =============================
    .p-index-case__image-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 20px;
    }
    .p-index-case__image-img {
      &:nth-of-type(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
      &:nth-of-type(2) {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
      }
      &:nth-of-type(3) {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
      }
      &:nth-of-type(4) {
        grid-column: 1 / 2;
        grid-row: 2 / 4;
      }
      &:nth-of-type(5) {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
      }
      &:nth-of-type(6) {
        grid-column: 3 / 4;
        grid-row: 2 / 4;
      }
    }
  }
}

// =============================
// faq
// =============================
.p-index-faq {
  margin-block: 90px;
  overflow: hidden;

  .p-index-faq__link {
    margin-inline: $padding;
    padding: 90px 32px;
    display: grid;
    place-content: center;
    background-color: $clr_bg;
    border: 1px solid $clr_bg;
    transition: var(--transition);
    transition-property: background-color border-color;

    & > * {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    .c-btn01 {
      span {
        --translate-size: 0px;
      }
    }

    &:hover,
    &:focus-visible {
      background-color: #fff;
      border-color: $clr_primary;

      .c-btn01 {
        color: $clr_primary;
        background-color: #fff;
        span {
          translate: calc(var(--translate-size) * -1) 0;
          &::after {
            background-color: $clr_primary;
          }
        }
      }

      .p-index-faq__loop {
        color: $clr_bg;
        span {
          svg {
            fill: $clr_bg;
          }
        }
      }
    }
  }

  // 中身テキスト
  // =============================
  .p-index-faq__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  .p-index-faq__title {
    margin-bottom: 24px;
    @include font_title;
    font-size: clamp(1.75rem, 1.25rem + 1.43vw, 2.5rem);
  }
  .p-index-faq__text {
    margin-bottom: 48px;
  }

  //ボタン
  .p-index-faq__btn {
    .-disable {
      pointer-events: none;
    }
  }

  // ループ文字
  // =============================
  .p-index-faq__loop {
    margin-left: calc(50% - 50vw);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    @include font_en;
    font-size: clamp(6.25rem, 2.083rem + 11.9vw, 12.5rem);
    color: #fff;
    -webkit-text-stroke: 1px $clr_primary;

    p {
      padding-inline: 20px;
      flex-shrink: 0;
      display: flex;
      flex-wrap: nowrap;
      gap: 40px;
      transition: var(--transition);
      transition-property: color;

      &:nth-of-type(1) {
        animation: loop 50s -25s linear infinite;
      }
      &:nth-of-type(2) {
        animation: loop2 50s linear infinite;
      }
    }

    span {
      svg {
        width: clamp(12.5rem, 4.167rem + 23.81vw, 25rem);
        fill: #fff;
        stroke: $clr_primary;
        transition: var(--transition);
        transition-property: fill;
      }
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    margin-block: 180px;

    .p-index-faq__inner {
      margin-inline: auto;
      width: 90%;
      max-width: 1560px;
    }

    .p-index-faq__link {
      padding: 180px 32px;
      margin-inline: 0;
    }

    .p-index-faq__loop {
      p {
        padding-inline: 50px;
        flex-shrink: 0;
        display: flex;
        flex-wrap: nowrap;
        gap: 100px;

        &:nth-of-type(1) {
          animation: loop 80s -40s linear infinite;
        }
        &:nth-of-type(2) {
          animation: loop2 80s linear infinite;
        }
      }
    }
    .p-index-faq__title {
      margin-bottom: 32px;
    }
    .p-index-faq__text {
      margin-bottom: 60px;
    }
  }
}

@keyframes loop {
  0% {
    translate: 100% 0;
  }
  100% {
    translate: -100% 0;
  }
}
@keyframes loop2 {
  0% {
    translate: 0 0;
  }
  100% {
    translate: -200% 0;
  }
}

// =============================
// company
// =============================
.p-index-company {
  .p-index-company__inner {
    @include inner;
  }

  // コンテナ
  // =============================
  .p-index-company__wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  //各アイテム
  .p-index-company__item {
    display: grid;
    overflow: hidden;

    & > * {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    img {
      transition: var(--transition);
      transition-property: scale;
    }

    .p-index-company__label {
      justify-self: end;
      align-self: end;
      pointer-events: none;
      z-index: 1;
    }

    &:hover,
    &:focus-visible {
      img {
        scale: 1.2;
      }

      .p-index-company__label {
        color: $clr_primary;
        background-color: #fff;
        span {
          translate: calc(var(--translate-size) * -1) 0;
          &::after {
            background-color: $clr_primary;
          }
        }
      }
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    margin-bottom: -74px;

    .p-index-company__inner {
      display: flex;
      justify-content: space-between;
      gap: 60px;
    }

    .p-index-company__title {
      flex-shrink: 0;
      position: sticky;
      top: var(--size-header-h);
    }

    .p-index-company__wrap {
      max-width: 580px;
    }
  }
}
