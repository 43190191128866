@use '../../fundation/index' as *;

// =============================
//
// =============================
.p-service-solution {
  // テキスト
  // =============================
  .p-service-solution__title {
    margin-bottom: 32px;
    text-align: center;
    font-size: clamp(1.125rem, 0.542rem + 1.67vw, 2rem);

    span {
      margin-inline: 3px;
      padding-inline: 8px;
      color: #fff;
      background-color: $clr_primary;
    }
  }
  .p-service-solution__text {
    margin-inline: auto;
    max-width: 920px;
  }

  // コンテナ
  // =============================
  .p-service-solution__wrap {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  //各アイテム
  .p-service-solution__item {
    padding-block: 40px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      width: min(calc(100% - $padding), calc(100% - (100vw - $contents_width) / 2));
      height: 100%;
      background-color: $clr_primary_light;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }
  .p-service-solution__item-outer {
    @include inner;
  }
  .p-service-solution__item-inner {
    padding-left: $padding;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  .p-service-solution__item-title {
    .c-head-border__main {
      font-size: clamp(1.5rem, 1.333rem + 0.48vw, 1.75rem);
    }
  }
  .p-service-solution__item-title-sub {
    font-size: font-size(16);
    text-transform: uppercase;
  }

  //アイテム反対 reverse
  .p-service-solution__item.-reverse {
    &::before {
      right: revert;
      left: 0;
    }

    .p-service-solution__item-inner {
      padding-left: revert;
      padding-right: $padding;
    }
  }

  // メディアクエリ
  // =============================
  @include mqUnder(1024) {
    .p-service-solution__text {
      @include inner;
    }
  }

  @include mq(large) {
    .p-service-solution__title {
      margin-bottom: 48px;

      span {
        margin-inline: 5px;
      }
    }
    .p-service-solution__text {
      font-size: font-size(18);
    }

    .p-service-solution__wrap {
      margin-top: 80px;
      gap: 60px;
    }

    .p-service-solution__item {
      padding-block: 80px;
    }
    .p-service-solution__item-inner {
      padding-left: min(120px, 6.25vw);
      flex-direction: row;
      gap: min(100px, 5.25vw);
    }

    .p-service-solution__item-textWrap,
    .p-service-solution__item-img {
      flex-basis: 50%;
    }
    .p-service-solution__item-textWrap {
      max-width: 460px;
    }
    .p-service-solution__item-img {
      max-width: 500px;
    }

    .p-service-solution__item.-reverse {
      .p-service-solution__item-inner {
        padding-left: revert;
        flex-direction: row-reverse;
        padding-right: min(120px, 6.25vw);
      }
    }
  }
}

// =============================
// 事業内容 business
// =============================
.p-service-business {
  margin-block: 90px;
  .p-service-business__inner {
    @include inner;
  }

  //タイトル
  .p-service-business__item-title {
    .c-head-border__sub {
      font-size: font-size(16);
    }
    .c-head-border__main {
      font-size: clamp(1.5rem, 1.167rem + 0.95vw, 2rem);
    }
  }

  //セクチョン
  .p-service-business__sec {
    &:not(:last-of-type) {
      margin-bottom: 60px;
    }
  }

  //コンテナ
  .p-service-business__secWrap {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &.-no-gap {
      gap: 0px;
    }
  }

  //グリッド
  .p-service-business__gridWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 10px;
  }

  //各アイテム
  .p-service-business__sec-item {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }

  .p-service-business__sec-item-img {
    figcaption {
      margin-top: 8px;
      display: block;
      text-align: center;
    }
  }

  // CVエリア
  // =============================
  .p-service-business__cv {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .p-service-business__cv-btn {
    padding: 32px 24px;
    width: 100%;
    display: block;
    background-color: $clr_bg;
    min-height: 128px;
    @include font_title;
    font-size: clamp(1.125rem, 1.042rem + 0.24vw, 1.25rem);
    border: 1px solid transparent;
    position: relative;
    overflow: hidden;
    transition: var(--transition);
    transition-property: border-color, background-color;

    &:hover,
    &:focus-visible {
      border-color: $clr_primary;
      background-color: #fff;

      .p-service-business__cv-btn-arrow {
        background-color: #fff;

        &::after {
          background-color: $clr_primary;
        }
      }

      .p-service-business__cv-btn-loop-inner {
        span svg path {
          fill: $clr_bg;
        }
      }
    }
  }

  //矢印
  .p-service-business__cv-btn-arrow {
    position: absolute;
    width: 32px;
    height: 32px;
    display: grid;
    place-content: center;
    background-color: $clr_primary;
    border: 1px solid $clr_primary;
    position: absolute;
    right: 12px;
    bottom: 12px;
    z-index: 1;
    transition: var(--transition);
    transition-property: background-color;

    &::after {
      content: '';
      width: 6px;
      height: 10px;
      background-color: #fff;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      transition: var(--transition);
      transition-property: background-color;
    }
  }

  //文字
  .p-service-business__cv-btn-loop {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    left: 0;
    bottom: -6px;

    & > * {
      flex-shrink: 0;
    }

    .p-service-business__cv-btn-loop-inner {
      padding-inline: 12px;
      display: flex;
      align-items: center;
      gap: 24px;

      span {
        svg path {
          fill: #fff;
          stroke: $clr_primary;
          transition: var(--transition);
          transition-property: fill;
        }
      }

      & > * {
        flex-shrink: 0;
        display: grid;
        place-content: center;
      }
    }
  }

  //case design 文字サイズ
  .p-service-business__cv-btn.-case {
    .p-service-business__cv-btn-loop-inner {
      &:first-of-type {
        animation: loop 42s -21s linear infinite;
      }
      &:last-of-type {
        animation: loop2 42s linear infinite;
      }

      span {
        svg {
          width: 340px;
        }
      }
    }

    &:hover,
    &:focus-visible {
      .p-service-business__cv-btn-loop-inner {
        &:first-of-type,
        &:last-of-type {
          animation-play-state: revert;
        }
      }
    }
  }
  //FAQ文字サイズ
  .p-service-business__cv-btn.-faq {
    .p-service-business__cv-btn-loop-inner {
      &:first-of-type {
        animation: loop 16s -8s linear infinite;
      }
      &:last-of-type {
        animation: loop2 16s linear infinite;
      }

      span {
        svg {
          width: 108px;
        }
      }
    }

    &:hover,
    &:focus-visible {
      .p-service-business__cv-btn-loop-inner {
        &:first-of-type,
        &:last-of-type {
          animation-play-state: revert;
        }
      }
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    margin-block: 160px;

    .p-service-business__sec {
      &:not(:last-of-type) {
        margin-bottom: 120px;
      }
    }

    .p-service-business__secWrap {
      gap: 60px;
    }

    .p-service-business__gridWrap {
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 280px), 1fr));
      gap: 40px 20px;
    }

    .p-service-business__sec-item {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      gap: min(100px, 5.25vw);
    }

    .p-service-business__sec-item.-reverse {
      flex-direction: row;
    }
    .p-service-business__sec-item.-align-start {
      align-items: start;
    }
    .p-service-business__sec-item-textWrap {
      max-width: 480px;
      flex-basis: 45%;
    }
    .p-service-business__sec-item-img {
      max-width: 600px;
      flex-basis: 55%;

      figcaption {
        margin-top: 12px;
      }
    }

    .p-service-business__cv {
      margin-top: 80px;
      flex-direction: row;
      gap: 40px;
    }
    .p-service-business__cv-btn {
      padding: 40px 50px;
      min-height: 160px;
    }

    .p-service-business__cv-btn-arrow {
      width: 40px;
      height: 40px;
      right: 16px;
      bottom: 16px;

      &::after {
        content: '';
        width: 8px;
        height: 12px;
      }
    }

    .p-service-business__cv-btn-loop {
      bottom: -14px;
    }

    //case design 文字サイズ
    .p-service-business__cv-btn.-case {
      .p-service-business__cv-btn-loop-inner {
        padding-inline: 20px;
        gap: 40px;

        &:first-of-type,
        &:last-of-type {
          animation-play-state: paused;
        }

        span {
          svg {
            width: 610px;
          }
        }
      }
    }
    //FAQ文字サイズ
    .p-service-business__cv-btn.-faq {
      .p-service-business__cv-btn-loop-inner {
        padding-inline: 20px;
        gap: 40px;

        &:first-of-type,
        &:last-of-type {
          animation-play-state: paused;
        }

        span {
          svg {
            width: 178px;
          }
        }
      }
    }

    .p-service-business__sec-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

// =============================
// 日創の対応可能領域 service
// =============================
.p-service-service {
  padding-block: 90px;
  background-color: $clr_primary_light;

  .p-service-service__inner {
    @include inner;
  }

  .p-service-service__tableWrap {
    padding-top: 26px;
  }

  .p-service-service__table {
    thead {
      tr {
        font-size: font-size(12);

        th:nth-of-type(2) {
          color: #fff;
          background-color: $clr_primary;
          position: relative;
          @include font_title;
          text-align: center;
          font-size: font-size(15);

          &::before {
            content: '';
            width: calc(100% + 10px);
            height: 25px;
            background-color: $clr_primary;
            position: absolute;
            left: -5px;
            bottom: calc(100% - 1px);
            z-index: 1;
          }

          span {
            display: inline-block;
            translate: 0 -10px;
          }
        }

        th {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #fff;
    }
    tr {
      & > *:nth-of-type(1) {
        width: 104px;
      }
      *:nth-of-type(2) {
        border-left: 5px solid $clr_primary;
        border-right: 5px solid $clr_primary;
      }

      &:last-of-type {
        *:nth-of-type(2) {
          border-bottom: 5px solid $clr_primary;
        }
      }
    }
    td {
      &:not(:first-of-type) {
        text-align: center;
        vertical-align: middle;

        svg {
          margin: auto;
          display: flex;
          justify-content: center;
        }
      }
      &:not(:nth-of-type(2)) {
        svg {
          g {
            stroke: $clr_default;
            stroke-width: 1px;
          }
        }
      }
    }
  }

  @include mq(medium) {
    .p-service-service__table {
      tr {
        & > *:nth-of-type(1) {
          width: auto;
        }
      }
    }
  }

  @include mq(large) {
    padding-block: 160px;

    .p-service-service__table {
      thead {
        th {
          font-size: font-size(16);
        }
      }
      thead {
        tr {
          th:nth-of-type(2) {
            font-size: font-size(24);
          }
        }
      }
      tr {
        & > *:nth-of-type(1) {
          width: auto;
        }
      }
    }
  }
}
