@use '../../fundation/index' as *;

.c-navigation {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 152px), 1fr));
  gap: 20px;
}

.c-navigation__item-link {
  padding: 0 4px 6px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @include font_title;
  font-size: font-size(16);
  color: $clr_primary;
  border-bottom: 1px solid $clr_primary;

  &::after {
    content: '';
    width: 12px;
    height: 8px;
    background-color: $clr_primary;
    clip-path: polygon(100% 0, 0 0, 50% 100%);
  }

  @include mq(large) {
    padding: 0 12px 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
