@use '../fundation/index' as *;

// =============================
// header
// =============================
.l-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--z-header);

  //ロゴ
  .l-header__logo {
    margin-left: 16px;
  }
  .l-header__logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40px;
    transition: var(--transition);
    transition-property: opacity;

    &:hover,
    &:focus-visible {
      opacity: 0.6;
    }
  }

  @include mq(large) {
    //inner
    .l-header__inner {
      padding-top: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    //ロゴ
    .l-header__logo {
      margin-left: 45px;
    }
    .l-header__logo-link {
      max-width: 90px;
    }
  }
}

// ハンバーガー
// =============================
.l-header__btn {
  width: 56px;
  height: 56px;
  background-color: transparent;
  position: relative;

  .l-header__btn-line {
    --borderWidth: 26px;

    width: var(--borderWidth);
    height: 2px;
    background-color: $clr_primary;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    transition: var(--transition);
    transition-property: background-color;

    &::before,
    &::after {
      content: '';
      width: var(--borderWidth);
      height: 2px;
      background-color: $clr_primary;
      position: absolute;
      left: 0;
      top: 7px;
      transition: var(--transition);
      transition-property: rotate top;
    }
    &::after {
      top: -7px;
    }
  }

  @include mq(large) {
    display: none;
  }
}
//SPオープン時
.is-open {
  .l-header__btn {
    .l-header__btn-line {
      background-color: transparent;

      &::before,
      &::after {
        top: 0;
        rotate: 45deg;
      }
      &::after {
        rotate: -45deg;
      }
    }
  }
}

// ドロワー
// =============================
.l-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  background: #fff;
  overflow: auto;
  overscroll-behavior: contain;
  transform: translateX(120%);
  z-index: -1;
  @include trans-normal;
  &::-webkit-scrollbar {
    display: none;
  }
  &[aria-hidden='false'] {
    transform: translateX(0);
  }

  @include mqUnder(1024) {
    min-height: 100vh;
  }

  @include mq(large) {
    margin-right: 40px;
    position: revert;
    overflow: visible;
    background-color: transparent;
    display: flex;
    justify-content: end;
    align-items: center;
    transform: translate(0) !important;
    z-index: 1;
  }
}
// メニュー
// =============================
.l-drawer__list {
  display: flex;
  flex-direction: column;

  //アイテム
  .l-drawer__item-link {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 700;
    transition: var(--transition);
    transition-property: color;

    &:hover,
    &:focus-visible {
      color: $clr_primary;
    }
  }
  .l-drawer__item-en {
    font-size: font-size(28);
    text-transform: uppercase;
  }

  //CVボタン
  .l-drawer__item.-cv {
    display: flex;
    justify-content: center;
    background-color: #fff;

    .l-drawer__item-link {
      padding: 25px;
      min-width: 240px;
      justify-content: center;
      align-items: center;
      color: $clr_primary;
      text-transform: uppercase;
      font-size: font-size(18);
      border: 1px solid $clr_primary;
      transition: var(--transition);
      transition-property: background-color color;

      &:hover,
      &:focus-visible {
        color: #fff;
        background-color: $clr_primary;
      }
    }
  }

  @include mqUnder(1024) {
    padding-top: min(145px, 15vh);
    padding-bottom: min(145px, 15vh);
    @include inner;

    //アイテム
    .l-drawer__item:not(.-cv) {
      padding-block: 24px;
      border-bottom: 1px solid $clr_border;
    }

    //CVボタン
    .l-drawer__item.-cv {
      margin-top: 60px;
    }
  }

  @include mq(large) {
    flex-direction: row;
    align-items: center;
    gap: 36px;

    .l-drawer__item-en {
      display: none;
    }

    //CVボタン
    .l-drawer__item.-cv {
      margin-left: 4px;
    }
  }
}
