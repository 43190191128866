@use '../../fundation/index' as *;

//何をやってるかわからなくなったので、参考にしないでください。

// =============================
// フェード
// =============================
.js-fade,
.js-fade__container {
  width: fit-content;
  display: inline-block;
  overflow: hidden;
}

.js-fade__container {
  @for $i from 1 through 10 {
    &:nth-of-type(#{$i}) {
      .js-fade__inner {
        transition: 0.6s calc(0.2s * $i) ease-out;
        transition-property: translate;
      }
    }
  }
}

.js-fade__inner {
  display: inline-block;
  translate: 0 120%;
  transition: 0.6s ease-out;
  transition-property: translate;
}

//active
.js-fade.is-active {
  .js-fade__inner {
    translate: 0 0;
  }
}

// =============================
// 横線ボーダーありのフェード
// =============================
.js-line-fade.c-head-border__main {
  overflow: hidden;

  &::before,
  &::after {
    scale: 0 1;
    transform-origin: center left;
    transition: 0.4s ease-out;
    transition-property: scale;
  }

  .js-line-fade__inner {
    display: inline-block;
    translate: 0 120%;
    transition: 0.4s 0.4s ease-out;
    transition-property: translate;
  }
}

.js-line-fade.c-head-border__main.is-active {
  &::before,
  &::after {
    scale: 1 1;
  }

  .js-line-fade__inner {
    translate: 0 0;
  }
}

// =============================
// 背景ありのフェード
// =============================
.js-bg-fade.p-index-solution__item-titleWrap.is-active,
.js-bg-fade.c-headWrap.is-active {
  &:before {
    animation: topLineAnim 0.6s ease-in-out forwards;
  }
  &:after {
    animation: bottomLineAnim 0.6s ease-in-out forwards;
  }

  .c-head,
  .p-index-solution__item-titleWrap-inner {
    &:before {
      animation: leftLineAnim 0.6s ease-in-out forwards;
    }
    &:after {
      animation: rightLineAnim 0.6s ease-in-out forwards;
    }

    .c-head__sub,
    .c-head__main,
    .p-index-solution__item-title {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
}

@keyframes topLineAnim {
  0% {
    scale: 0 1;
    transform-origin: center left;
  }
  50% {
    scale: 1 1;
    transform-origin: center left;
  }
  50.1% {
    transform-origin: center right;
  }
  100% {
    scale: 0 1;
    transform-origin: center right;
  }
}
@keyframes bottomLineAnim {
  0% {
    scale: 0 1;
    transform-origin: center right;
  }
  50% {
    scale: 1 1;
    transform-origin: center right;
  }
  50.1% {
    transform-origin: center left;
  }
  100% {
    scale: 0 1;
    transform-origin: center left;
  }
}
@keyframes leftLineAnim {
  0% {
    scale: 1 0;
    transform-origin: top center;
  }
  50% {
    scale: 1 1;
    transform-origin: top center;
  }
  50.1% {
    transform-origin: bottom center;
  }
  100% {
    scale: 1 0;
    transform-origin: bottom center;
  }
}
@keyframes rightLineAnim {
  0% {
    scale: 1 0;
    transform-origin: bottom center;
  }
  50% {
    scale: 1 1;
    transform-origin: bottom center;
  }
  50.1% {
    transform-origin: top center;
  }
  100% {
    scale: 1 0;
    transform-origin: top center;
  }
}
