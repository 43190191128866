@use '../../fundation/index' as *;

.p-policy {
  .p-policy__inner {
    margin-inline: auto;
    width: 100%;
    max-width: $contents_width;
  }

  .p-policy__bg {
    padding: 60px 24px;
    background-color: $clr_bg;
  }

  //先頭の文章
  .p-policy__text {
    padding-inline: $padding;
    margin-bottom: 60px;
  }

  //アイテム共通かしょ
  .p-policy__item {
    margin-bottom: 40px;
  }

  .p-policy__item-title {
    margin-bottom: 20px;
    display: flex;
    align-items: first baseline;
    gap: 12px;
    @include font_title;
    font-size: clamp(1.125rem, 1.042rem + 0.24vw, 1.25rem);

    span {
      flex-shrink: 0;
      @include font_en;
    }
  }
  .p-policy__item-title.-small {
    margin-bottom: 14px;
    font-size: font-size(15);
    font-weight: 500;
  }

  .p-policy__item-wrap {
    margin-bottom: 18px;
    padding-left: 26px;
  }

  .p-policy__item-text {
    margin-bottom: 20px;
  }

  .p-policy__table {
    min-width: revert;

    th,
    td {
      padding: 15px 24px;
      display: block;
      border: none;
    }

    th {
      background-color: $clr_bg_secondary;
    }
    td {
      background-color: #fff;
    }
  }

  @media screen and (min-width: 1180px) {
    .p-policy__text {
      padding-inline: 0px;
    }
  }

  @include mq(large) {
    .p-policy__bg {
      padding: 80px 100px;
    }
    .p-policy__text {
      margin-bottom: 80px;
    }
    .p-policy__item {
      margin-bottom: 60px;
    }
    .p-policy__item-title {
      margin-bottom: 24px;
    }
    .p-policy__item-title.-small {
      margin-bottom: 16px;
      font-size: font-size(16);
    }
    .p-policy__item-wrap {
      margin-bottom: 20px;
      padding-left: 32px;
    }
    .p-policy__table {
      th,
      td {
        display: revert;
        border: 1px solid $clr_border;
      }

      th {
        width: 200px;
      }
    }
  }
}
