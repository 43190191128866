@use '../../fundation/index' as *;

// =============================
// ご挨拶 message
// =============================
.p-company-message {
  .p-company-message__inner {
    @include inner;
  }

  //コンテナ
  .p-company-message__wrap {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }

  //文章
  .p-company-message__text {
    margin-bottom: 16px;
  }
  .p-company-message__name {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 24px;

    span {
      font-size: font-size(13);
    }

    strong {
      font-size: clamp(1.25rem, 1.083rem + 0.48vw, 1.5rem);
    }
  }

  @include mq(large) {
    .p-company-message__wrap {
      flex-direction: row-reverse;
      align-items: center;
      gap: min(100px, 5.25vw);
    }
    .p-company-message__textWrap {
      flex-basis: 50%;
    }

    .p-company-message__name {
      span {
        font-size: font-size(14);
      }
    }

    .p-company-message__img {
      flex-basis: 42.5%;
    }
  }
}

// =============================
// 会社概要 data
// =============================
.p-company-data {
  .p-company-data__inner {
    @include inner;
  }

  //テーブル
  .p-company-data__table {
    min-width: revert;

    th,
    td {
      display: block;
      border: 1px solid $clr_border;
    }

    th {
      background-color: $clr_primary_light;
    }
  }

  .p-company-data__table-wrap {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .p-company-data__table-wrap-link {
      color: $clr_default;
      text-decoration: none;
      display: block;
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    .p-company-data__table {
      th,
      td {
        display: revert;
      }

      th {
        width: 200px;
      }
    }

    .p-company-data__table-wrap {
      .p-company-data__table-wrap-link {
        margin-right: 1em;
        display: revert;
      }
    }
  }
}

// =============================
// アクセス access
// =============================
.p-company-access {
  .p-company-access__inner {
    @include inner;
  }

  //各アイテム
  .p-company-access__wrap {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;

    &:not(:last-of-type) {
      margin-bottom: 60px;
    }
  }
  //マップ
  .p-company-access__map {
    width: 100%;
    aspect-ratio: 327 / 218;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .p-company-access__container {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  //テキスト
  .p-company-access__title {
    font-size: clamp(1.5rem, 1.167rem + 0.95vw, 2rem);
    @include font_title;
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    .p-company-access__wrap {
      flex-direction: row-reverse;
      gap: min(80px, 4.2vw);
    }

    .p-company-access__map {
      flex-basis: 52%;
    }
    .p-company-access__textWrap {
      flex-basis: 42%;
    }
    .p-company-access__container {
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }
  }
}

// =============================
// 沿革 history
// =============================
.p-company-history {
  .p-company-history__inner {
    @include inner;
  }

  .p-company-history__slider {
    padding-bottom: 82px;
    margin-right: calc(50% - 50vw);
  }

  .p-company-history__item {
    padding-top: 42px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-color: $clr_primary;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $clr_border;
      position: absolute;
      left: 0;
      top: 12px;
    }
  }

  .p-company-history__item-title {
    font-size: clamp(1.375rem, 1.292rem + 0.24vw, 1.5rem);
  }
  .p-company-history__item-list {
    max-width: 250px;
  }

  .p-company-history__slider-wrap {
    position: relative;
  }
  //swiper 矢印
  .p-company-history__slider-arrow {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 6px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  .js-button-prev,
  .js-button-next {
    width: 50px;
    height: 50px;
    border: 1px solid $clr_primary;
    display: grid;
    place-content: center;
    background-color: #fff;
    cursor: pointer;

    &::after {
      content: '';
      width: 12px;
      height: 18px;
      background-color: $clr_primary;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      position: relative;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
  .js-button-prev {
    &::after {
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    .p-company-history__item-list {
      max-width: 330px;
    }

    .p-company-history__slider {
      padding-bottom: 0;
    }

    .p-company-history__slider-arrow {
      left: revert;
      right: 0;
      bottom: calc(100% + 60px);
    }
  }
}
