@use '../../fundation/index' as *;

.c-btn01 {
  padding: 16px 20px;
  width: fit-content;
  min-width: 200px;
  @include font_title;
  color: #fff;
  background-color: $clr_primary;
  display: block;
  border: 1px solid $clr_primary;
  transition: var(--transition);
  transition-property: background-color color;

  span {
    --translate-size: 0px;

    margin-left: auto;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: var(--transition);
    transition-property: translate;

    //三角形
    &::after {
      content: '';
      width: 7px;
      height: 10px;
      background-color: #fff;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      transition: var(--transition);
      transition-property: background-color;
    }
  }

  &:hover,
  &:focus-visible {
    color: $clr_primary;
    background-color: #fff;

    span {
      translate: calc(var(--translate-size) * -1) 0;
      &::after {
        background-color: $clr_primary;
      }
    }
  }

  @include mq(large) {
    padding: 20px 24px;
    min-width: 230px;
    gap: 16px;

    span {
      &::after {
        width: 8px;
        height: 12px;
      }
    }
  }
}
