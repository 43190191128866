@use '../../fundation/index' as *;

/*  404
================================================ */
.p-page404 {
  padding-top: 90px;
  .p-page404__inner {
    @include inner();
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  //.p-page404__head{
  //	text-align: center;
  //}
  @include mq(medium) {
    padding-top: 160px;

    .p-page404__inner {
      @include inner();
      gap: 24px;
    }
  }
}
