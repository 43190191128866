@use '../../fundation/index' as *;

.p-hero {
  margin-top: 56px;

  .p-hero__inner {
    min-height: 375px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
  }

  //テキスト
  .p-hero__textWrap {
    @include inner;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: end;
    z-index: 1;
    color: #fff;
  }
  .p-hero__textWrap.-center {
    grid-row: 1 / 3;
    align-self: center;
  }
  .p-hero__title {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .p-hero__title-en {
    margin-block: calc((1em - 1em * 1.2) / 2);
    font-size: clamp(2.5rem, 7.14vw, 6.25rem);
    text-transform: uppercase;
    @include font_en;
    white-space: nowrap;
  }
  .p-hero__title-ja {
    font-size: clamp(0.938rem, 0.563rem + 1.07vw, 1.5rem);
  }

  .p-hero__text {
    margin-top: 20px;
  }

  //画像
  .p-hero__bg {
    margin-bottom: 0;
    grid-column: 1 / 2;
    grid-row: 1 / 3;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center right;
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    margin-top: 170px;

    .p-hero__inner {
      min-height: 500px;
    }

    .p-hero__textWrap {
      grid-row: 1 / 3;
      align-self: center;
    }

    .p-hero__text {
      margin-top: 48px;
    }
  }
}
