@use '../../fundation/index' as *;

// =============================
// 背景が黒いタイトル
// =============================
.c-headWrap {
  width: fit-content;
  height: fit-content;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 120%;
    height: 1px;
    background-color: $clr_default;
    position: absolute;
    left: -10%;
    scale: 0 1;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}
.c-head {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 120%;
    background-color: $clr_default;
    position: absolute;
    top: -10%;
    scale: 1 0;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}
.c-head__sub {
  padding: 4px 16px;
  width: fit-content;
  color: #fff;
  position: relative;
  z-index: 1;
  @include font_title;
  font-size: clamp(0.938rem, 0.563rem + 1.07vw, 1.5rem);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition: 0.4s 0.6s ease-in-out;
  transition-property: clip-path;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $clr_default;
    z-index: -1;
  }
}
.c-head__main {
  padding: 10px 22px;
  width: fit-content;
  color: #fff;
  position: relative;
  z-index: 1;
  @include font_en;
  text-transform: uppercase;
  font-size: clamp(2.313rem, 0.854rem + 4.17vw, 4.5rem);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition: 0.4s 0.6s ease-in-out;
  transition-property: clip-path;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $clr_default;
    z-index: -1;
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    padding: 16px 24px;
  }
}

// =============================
// 上下線タイトル
// =============================
.c-head-border {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.c-head-border__sub {
  padding: 4px 12px;
  width: fit-content;
  color: #fff;
  @include font_title;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $clr_default;
    z-index: -1;
  }
}
.c-head-border__main {
  padding-block: 10px;
  width: fit-content;
  font-size: clamp(1.75rem, 1.25rem + 1.43vw, 2.5rem);
  @include font_title;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $clr_default;
    position: absolute;
    left: 0;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

// =============================
// 横線タイトル
// =============================
.c-head-line {
  padding-left: 10px;
  @include font_title;
  font-size: clamp(1.25rem, 1.083rem + 0.48vw, 1.5rem);
  position: relative;

  &::before {
    content: '';
    width: 4px;
    height: calc(100% - (1em * 1.9 - 1em) + 4px);
    position: absolute;
    left: 0;
    top: calc((1em * 1.9 - 1em) / 2 - 2px);
    background-color: $clr_primary;
  }

  @include mq(large) {
    padding-left: 16px;
    border-width: 6px;
  }
}
