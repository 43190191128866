@use '../../fundation/index' as *;

.u-view-small{
	@include mq (medium){
		display: none !important;
	}
}
.u-view-medium{
	@include mq (small) {
		display: none !important;
	}
	@include mq (large) {
		display: none !important;
	}
}
.u-view-large{
	@media all and ( width < $boundary ){
		display: none !important;
	}
	@media all and ($contents_plus_padding <= width){
		display: none !important;
	}
}
.u-view-full{
	@media all and (width < $contents_plus_padding){
		display: none !important;
	}
}
.u-view-under-medium{
	@include mq (large){
		display: none !important;
	}
}
.u-view-upper-large{
	@media all and ( width < $boundary ) {
		display: none !important;
	}
}


//accessibility
.u-visually-hidden:not(:focus-visible) {
  position: absolute;
  border: 0;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}