@use '../../fundation/index' as *;

.p-faq {
  .p-faq__inner {
    @include inner;
  }

  // =============================
  // ナビゲーション
  // =============================
  .p-faq__nav {
    margin-bottom: 60px;

    @include mq(large) {
      margin-bottom: 80px;
    }
  }

  // =============================
  // faqコンテンツ
  // =============================
  .p-faq__sec {
    margin-bottom: 60px;
  }

  .p-faq__title {
    font-size: clamp(1.5rem, 1.167rem + 0.95vw, 2rem);
    @include font_title;
  }

  .p-faq__wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .p-faq__item {
    background-color: $clr_bg;
  }

  //タイトル
  .p-faq__item-title {
    padding: 24px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: first baseline;
    gap: 10px;

    //Qの文字
    &::before {
      content: 'Q';
      @include font_en;
      font-size: clamp(1.5rem, 1.333rem + 0.48vw, 1.75rem);
      translate: 0 2px;
    }
  }

  //アイコン
  .p-faq__item-title-icon {
    --icon-size: 16px;

    width: var(--icon-size);
    height: var(--icon-size);
    display: grid;
    place-content: center;
    align-self: center;

    &::before,
    &::after {
      content: '';
      width: var(--icon-size);
      height: 2px;
      background-color: $clr_primary;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      transition: var(--transition);
      transition-property: rotate;
    }
    &::after {
      rotate: 90deg;
    }
  }

  //コンテンツ
  .js-accordion__contents {
    display: grid;
  }
  .p-faq__item-contentsWrap {
    margin: 0px 24px 24px;
    padding: 16px 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    gap: 16px;
    background-color: #fff;

    //Aの文字
    &::before {
      content: 'A';
      @include font_en;
      font-size: clamp(1.5rem, 1.333rem + 0.48vw, 1.75rem);
      color: $clr_primary;
    }
  }

  // details openの時
  // =============================
  .p-faq__item[open] {
    .p-faq__item-title-icon {
      &::after {
        rotate: 0deg;
      }
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    .p-faq__sec {
      margin-bottom: 80px;
    }

    .p-faq__wrap {
      gap: 24px;
    }

    .p-faq__item-title {
      padding: 24px 40px;
    }
    .p-faq__item-title-icon {
      --icon-size: 20px;
    }

    .p-faq__item-contentsWrap {
      margin: 0px 40px 32px;
      padding: 24px 40px;
    }
  }
}
