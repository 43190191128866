@charset "UTF-8";

/*  width
================================================ */
$contents_width: 1180px;
$padding: 24px;
$contents_plus_padding: calc($contents_width + ($padding * 2));
$adjust: calc((1024px - 768px) / 2);
$boundary: 1024px;

//inner
@mixin inner($contentWidth: $contents_width) {
  width: min(100%, #{$contentWidth + ($padding * 2)});
  margin: 0 auto;
  padding-left: $padding;
  padding-right: $padding;
  @media all and (768px <= width < $contents_plus_padding) {
    width: min(100%, $contentWidth + $adjust - ($padding * 2));
    padding-left: $adjust;
    padding-right: $adjust;
  }
}
@mixin innerL {
  @include inner(1600);
}

// breakpoints
$breakpoints: (
  'small': 'all and ( width < 768px)',
  'medium': 'all and ( 768px <= width )',
  'under': 'all and ( width <' + $boundary + ' )',
  //768 + $adjust x 2(両サイド＋768px でコンテンツ自体は768pxのサイズでおさまる範囲)
  'large': 'all and ( ' + $boundary + ' <= width )',
  //768 + $adjust x 2(両サイド＋768px でコンテンツ自体は768pxのサイズでおさまる範囲)
  'full': 'all and ( ' + $contents_plus_padding + ' < width )',
  //'max': 'all and ( px <= width	)',
) !default;

@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin mqUnder($breakpoint) {
  @media all and (width <= #{$breakpoint}px) {
    @content;
  }
}
@mixin mqUpper($breakpoint) {
  @media all and (#{$breakpoint}px <= width) {
    @content;
  }
}

@mixin mqMinMax($min, $max) {
  @media all and (#{$min}px <= width <= #{$max}px) {
    @content;
  }
}

/*  font
================================================ */
@function font-size($font-size-px) {
  @return calc($font-size-px / 16 * 1rem);
}
@mixin font_base() {
  font-family: 'M PLUS 1', 'ヒラギノ角ゴ ProN W3', Meiryo, sans-serif;
  line-height: 1.9;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: font-size(15);
  @include mq(medium) {
    font-size: font-size(16);
  }
}
@mixin font_title() {
  font-weight: 900;
  letter-spacing: 0.04em;
  line-height: 1.7;
}

@mixin font_en() {
  font-family: 'Ubuntu', 'M PLUS 1', 'ヒラギノ角ゴ ProN W3', Meiryo, sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

/*  transition
================================================ */
$func: cubic-bezier(0.59, 0.18, 0.13, 0.76);
@mixin trans-normal($prop: all) {
  transition: 0.3s $func;
  transition-property: $prop;
}

/*  color
================================================ */

//色を追加するときは、root変数にも追加する
$clr_default: #141414;
$clr_txt_gray: #848484;
$clr_primary: #0a9ae0;
$clr_primary_light: #f1f1f5;
$clr_green: #00b75c;
$clr_orange: #e8762e;
$clr_border: #d7dbde;
$clr_border_secondary: #e6ecf0;
$clr_bg: #dcecf5;
$clr_bg_secondary: #f5f5f5;

:root {
  --clr_default: #{$clr_default};
  --clr_txt_gray: #{$clr_txt_gray};
  --clr_primary: #{$clr_primary};
  --clr_primary_light: #{$clr_primary_light};
  --clr_green: #{$clr_green};
  --clr_orange: #{$clr_orange};
  --clr_border: #{$clr_border};
  --clr_border_secondary: #{$clr_border_secondary};
  --clr_bg: #{$clr_bg};
  --clr_bg_secondary: #{$clr_bg_secondary};

  //z-index
  --z-go-top: 10;
  --z-header: 200;
  --z-header-inner: 250;
  --z-drawer: 150;
  --z-hamburger: 300;
  --z-skip-main: 999;
  --z-modal: 9999;
  --z-loading: calc(infinity);

  //headerH
  --size-header-h: 56px;

  //transition
  --transition: 0.3s ease-in-out;

  @include mq(large) {
    --size-header-h: 121px;
  }
}
