@use '../../fundation/index' as *;

// =============================
// ナビゲーション
// =============================
.p-works-booth__nav {
  margin-bottom: 60px;

  @include mq(large) {
    margin-bottom: 80px;
  }
}

// =============================
// ブース
// =============================
.p-works-booth {
  .p-works-booth__inner {
    @include inner;
  }

  //セクション
  .p-works-booth__sec {
    &:not(:last-of-type) {
      margin-bottom: 60px;
    }
  }

  //タイトル
  .p-works-booth__sec-title {
    font-size: clamp(1.5rem, 1.167rem + 0.95vw, 2rem);
    @include font_title;
  }

  //コンテナ
  .p-works-booth__sec-wrap {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  //画像
  .p-works-booth__sec-img {
    figcaption {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .-main,
      .-sub {
        display: block;
      }

      .-sub {
        font-size: font-size(13);
        color: $clr_txt_gray;
      }
    }
  }

  @include mq(large) {
    .p-works-booth__sec {
      &:not(:last-of-type) {
        margin-bottom: 80px;
      }
    }

    .p-works-booth__sec-wrap {
      grid-template-columns: repeat(3, 1fr);
    }

    .p-works-booth__sec-img {
      figcaption {
        margin-top: 16px;
      }
    }
  }
}

// =============================
// 業務経歴
// =============================
.p-works-history {
  margin-block: 90px 100px;
  .p-works-history__inner {
    @include inner;
  }

  //テーブル
  .p-works-history__table {
    thead {
      tr {
        th:first-of-type {
          border-top: 1px solid $clr_border;
          border-left: 1px solid $clr_border;
          background-color: $clr_bg;
        }
      }
    }
    tr {
      &:first-of-type {
        td {
          border-top-color: #fff;
        }
      }
      & > *:first-of-type {
        width: 208px;
      }
    }
  }

  @include mq(large) {
    margin-block: 160px 200px;
  }
}
