@use '../../fundation/index' as *;

.c-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .c-list__item {
    --marker-size: 5px;
    --marker-position: calc(var(--marker-size) / 2);

    padding-left: 1.5em;
    position: relative;

    &::before {
      content: '';
      width: 1.5em;
      height: calc(1em * 1.9);
      height: 1lh;
      background-color: currentColor;
      position: absolute;
      left: 0;
      top: 0;
      clip-path: polygon(calc(50% - var(--marker-position)) calc(50% - var(--marker-position)), calc(50% + var(--marker-position)) calc(50% - var(--marker-position)), calc(50% + var(--marker-position)) calc(50% + var(--marker-position)), calc(50% - var(--marker-position)) calc(50% + var(--marker-position)));
    }
  }

  @include mq(large) {
    gap: 10px;
  }
}
