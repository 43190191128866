@use '../fundation/index' as *;

// =============================
// contact
// =============================
.l-footer-contact {
  margin-top: 100px;
  border-top: 1px solid $clr_primary;
  border-bottom: 1px solid $clr_primary;

  .privacypolicy & {
    margin-top: 0;
  }

  .l-footer-contact__link {
    padding-block: 90px;
    color: #fff;
    background-color: $clr_primary;
    display: block;
    transition: var(--transition);
    transition-property: background-color;

    //ホバー
    &:hover,
    &:focus-visible {
      background-color: #fff;

      .l-footer-contact__title {
        color: rgba($clr_bg, 1);
      }
      .l-footer-contact__text {
        color: $clr_default;
      }

      .l-footer-contact__btn-inner {
        color: $clr_primary;
        translate: -90% 0;
      }
    }
  }
  .l-footer-contact__inner {
    @include inner;
  }

  //テキスト
  .l-footer-contact__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(4rem, -0.042rem + 11.55vw, 10.063rem);
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.2);
    transition: 0.2s ease-in-out;
    transition-property: color;
  }

  .l-footer-contact__text {
    text-align: center;
    font-size: font-size(14);
    transition: var(--transition);
    transition-property: color;
  }

  //ボタン
  .l-footer-contact__btn {
    display: flex;
    justify-content: end;
  }
  .l-footer-contact__btn-inner {
    @include font_title;
    font-size: clamp(1.25rem, 0.667rem + 1.67vw, 2.125rem);
    display: flex;
    align-items: center;
    gap: 28px;
    transition: 0.48s ease-in-out;
    transition-property: color, translate;

    svg {
      width: 40px;
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    margin-top: 200px;
    .privacypolicy & {
      margin-top: 200px;
    }

    .l-footer-contact__wrap {
      display: grid;
      place-content: center;
      flex-shrink: 0;

      .l-footer-contact__title,
      .l-footer-contact__text {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        align-self: center;
      }
    }

    .l-footer-contact__btn {
      width: 100%;
    }

    .l-footer-contact__btn-inner {
      gap: 40px;

      svg {
        width: 64px;
      }
    }
  }

  @include mq(full) {
    .l-footer-contact__link {
      padding-block: 150px;
    }
    .l-footer-contact__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @include mqUpper(1560) {
    .l-footer-contact__wrap {
      margin-left: min(-190px, -10vw);
    }
  }
}

.service {
  .l-footer-contact {
    margin-top: 0;
  }
}

// =============================
// footerメイン
// =============================
.l-footer-main {
  padding-block: 90px 20px;
  background-color: #f1f1f5;
  overflow: hidden;

  .l-footer-main__inner {
    @include inner;
  }

  // ロゴ
  // =============================
  .l-footer-main__logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60px;
    transition: var(--transition);
    transition-property: opacity;

    &:hover,
    &:focus-visible {
      opacity: 0.6;
    }
  }

  // ナビゲーション
  // =============================
  .p-footer-main__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;
    font-size: font-size(14);
  }

  .p-footer-main__list-link {
    font-size: font-size(14);
    transition: var(--transition);
    transition-property: opacity;

    &:hover,
    &:focus-visible {
      opacity: 0.6;
    }
  }

  //プラポリ
  .l-footer-main__policy {
    font-size: font-size(12);
    color: $clr_txt_gray;
  }
  .l-footer-main__policy-link {
    transition: var(--transition);
    transition-property: opacity;

    &:hover,
    &:focus-visible {
      opacity: 0.6;
    }
  }

  // 住所
  // =============================
  .l-footer-main__info {
    display: flex;
    flex-direction: column;
    gap: 90px;
  }

  .l-footer-main__addressWrap {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  //住所テキスト
  .l-footer-main__address {
    font-size: font-size(12);
    display: flex;
    flex-direction: column;
    gap: 8px;

    strong {
      @include font_title;
    }
  }
  .l-footer-main__address-link {
    display: block;
    width: fit-content;
    color: $clr_default;
    text-decoration: none;
  }

  // コピー
  // =============================
  .p-footer-main__copy {
    @include font_en;
    font-size: font-size(13);
    color: $clr_txt_gray;
  }

  @include mqUnder(1024) {
    .p-footer-main__copy {
      margin-top: 20px;
      padding-top: 20px;
      font-size: font-size(12);
      border-top: 1px solid $clr_border;
    }
  }

  // メディアクエリ
  // =============================
  @include mq(large) {
    padding-block: 160px 60px;

    .l-footer-main__wrap {
      margin-bottom: 140px;
      display: flex;
      align-items: center;
      gap: 70px;
    }

    // ロゴ
    // =============================
    .l-footer-main__logo-link {
      max-width: 120px;
    }

    // 住所
    // =============================
    .l-footer-main__info {
      padding-top: 60px;
      flex-direction: row;
      justify-content: space-between;
      position: relative;

      &::before {
        content: '';
        width: 100vw;
        height: 1px;
        background-color: $clr_border;
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
      }
    }
    .l-footer-main__addressWrap {
      margin-top: 0;
      flex-direction: row;
      gap: 60px;
    }
  }

  @include mqUpper(1200) {
    .l-footer-main__address {
      flex-direction: row;
      gap: 24px;
    }
  }
}
