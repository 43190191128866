@use '../../fundation/index' as *;

.c-breadcrumb {
  --list-gap: 8px;

  margin-block: 24px;
  @include inner;
}

.c-breadcrumb__list {
  display: flex;
  align-items: center;
  gap: var(--list-gap);

  a {
    font-size: font-size(13);
    display: flex;
    align-items: center;
    gap: var(--list-gap);
    text-decoration: none;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  & > span:last-of-type {
    span {
      color: $clr_txt_gray;
      font-size: font-size(13);
      display: flex;
      align-items: center;
      gap: var(--list-gap);

      &::before {
        content: '';
        width: 5px;
        height: 8px;
        background-color: $clr_default;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
      }
    }
  }
}

// リンクじゃない時
